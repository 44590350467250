import { Link } from "gatsby";
import React from "react";
// import background from "../../images/gokenNihon/Union.png";

const Jobs = ({ heading, paragraph, bannerImage, jobList = [] }) => {
  return (
    <div className="gnx__jobs-list-wrapper section-br pb-3em">
      {/* <img className="job-background-image" src={background} alt="" /> */}

      {/* <div className="fullscreen-image desktop-service-image">
        <img src={bannerImage} alt="" />
      </div> */}
      <div className="gnx__deatils-wrapper  pad-bottom-3rem w-80-wrapper goken-gray-text">
        <h2 className="semi-page-heading margin-0  titlecase-text">
          {heading}
        </h2>
        <div className="red-underline"></div>

        <div className="gnx-job-list-section">
          {jobList.map(
            ({
              title,
              location,
              jobType,
              experience,
              qualification,
              sections,
            }) => {
              return (
                <div className="job-list-card">
                  <h3 className="gnx-job-title title fw-700 color-ja titlecase-text">
                    {title}
                  </h3>
                  <div className="job-detail-wrapper">
                    <div className="left-text-wrapper">
                      <h4 className="fw-300 para light-gray-text margin-0">
                        {location?.title}:
                      </h4>
                      <h4 className="fw-300 para light-gray-text margin-0">
                        {jobType?.title}:
                      </h4>
                      <h4 className="fw-300 para light-gray-text margin-0">
                        {experience?.title}:
                      </h4>
                      <h4 className="fw-300 para light-gray-text margin-0">
                        {qualification?.title}:
                      </h4>
                    </div>
                    <div className="right-text-wrapper">
                      <h5 className="fw-400 para margin-0">
                        {location?.value}
                      </h5>
                      <h5 className="fw-400 para margin-0">{jobType?.value}</h5>
                      <h5 className="fw-400 para margin-0">
                        {experience?.value}
                      </h5>
                      <h5 className="fw-400 para margin-0">
                        {qualification?.value}
                      </h5>
                    </div>
                    {/* <div className="grid-wrapper fw-600">
                      <div className="location">
                        <h4 className="fw-600 para light-gray-text margin-0">
                          Location
                        </h4>
                        <h5 className="fw-600 para margin-0">{location}</h5>
                      </div>
                      <div className="job-type">
                        <h4 className="fw-600 para light-gray-text margin-0">
                          Job Type
                        </h4>
                        <h5 className="fw-600 para margin-0">{jobType}</h5>
                      </div>
                      <div className="experience">
                        <h4 className="fw-600 para light-gray-text margin-0">
                          Experience
                        </h4>
                        <h5 className="fw-600 para margin-0">{experience}</h5>
                      </div>
                    </div>
                    <div className="grid-wrapper m-top-3em fw-600 p-bottom-3em section-br">
                      <div className="experience">
                        <h4 className="fw-600 para light-gray-text margin-0">
                          Qualification
                        </h4>
                        <h5 className="fw-600 para margin-0">
                          {qualification}
                        </h5>
                      </div>
                    </div> */}
                  </div>
                  <div className="job-details-section">
                    {sections.map(({ title, list }) => {
                      return (
                        <div className="job-details-web-half-section fw-400">
                          <h4 className="bold semi-title fw-700">{title}</h4>
                          <ul className="fw-400">
                            {list.map((point) => {
                              return <li>{point}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      {/* <div className="services-cta-wrapper"> */}
      <Link to="/careers/">
        <div className="btn-container ">
          <button className={`primary-btn bg-ja `}>今すぐ申し込む</button>
        </div>
      </Link>
    </div>
    // </div>
  );
};

export default Jobs;
